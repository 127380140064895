import { useState } from 'react';
import { Key, Selection } from 'react-aria-components';
import { debounce } from '@gonfalon/es6-utils';
import {
  Button,
  Group,
  IconButton,
  Input,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
  SearchField,
} from '@launchpad-ui/components';
import { Icon } from '@launchpad-ui/icons';

import styles from './MultiSelectWithSearch.module.css';

type MultiSelectWithSearchProps = {
  options: MultiSelectOption[];
  ariaLabel: string;
  defaultValues?: string[];
  placeholder?: string;
  onSelect?: (selection: string[]) => void;
  searchBreakpoint?: number;
  maxDisplayedOptions?: number;
  disallowEmptySelection?: boolean;
  isDisabled?: boolean;
  shortenLabels?: boolean;
};

export interface MultiSelectOption {
  value: string;
  label?: string;
}

export function MultiSelectWithSearch({
  options,
  ariaLabel,
  defaultValues,
  placeholder,
  onSelect,
  searchBreakpoint = 1,
  maxDisplayedOptions = 2,
  disallowEmptySelection = false,
  isDisabled = false,
  shortenLabels = false,
}: MultiSelectWithSearchProps) {
  const initialSet = defaultValues ? new Set<Key>(defaultValues) : new Set<Key>();
  const [displayedOptions, setDisplayedOptions] = useState<MultiSelectOption[]>(options);
  const [selectedKeys, setSelectedKeys] = useState<Selection>(initialSet);

  const handleSelect = (selected: Selection) => {
    if (selected === 'all') {
      return;
    }
    setSelectedKeys(selected);
    const element = Array.from(selected).map((key: Key) => key.toString());
    onSelect && onSelect(element);
  };

  function handleSearch(newSearchValue: string) {
    setDisplayedOptions(options.filter((o) => o.label?.includes(newSearchValue)));
  }

  const debounced = debounce(handleSearch, 300);

  const optionsComparator = (a: MultiSelectOption, b: MultiSelectOption) => {
    if (selectedKeys === 'all') {
      return 0;
    }
    if (selectedKeys.has(a.value) && !selectedKeys.has(b.value)) {
      return -1;
    } else if (!selectedKeys.has(a.value) && selectedKeys.has(b.value)) {
      return 1;
    } else {
      return 0;
    }
  };

  const getSelectedLabelsString = () => {
    const selectedLabels = options
      .filter((o) => Array.from(selectedKeys).slice(0, maxDisplayedOptions).includes(o.value))
      .map((o) => o.label);

    const joined = selectedLabels.join(', ');

    if (shortenLabels) {
      const truncateAtLength = 12;
      if (joined.length > 20) {
        const shortenedLabels = selectedLabels.map((label) =>
          label && label.length > truncateAtLength ? `${label?.substring(0, truncateAtLength).trim()}...` : label,
        );
        return shortenedLabels.join(', ');
      }
    }
    return joined;
  };

  return (
    <MenuTrigger onOpenChange={(isOpen) => isOpen && setDisplayedOptions([...options].sort(optionsComparator))}>
      <Button className={styles.dropdownButton} isDisabled={isDisabled}>
        <span className={styles.selectedOptions}>{getSelectedLabelsString()}</span>
        {Array.from(selectedKeys).length > maxDisplayedOptions && (
          <span className={styles.moreOptions}>{`+${Array.from(selectedKeys).length - maxDisplayedOptions} more`}</span>
        )}
        <Icon name="chevron-down" size="small" />
      </Button>
      <Popover placement="bottom left">
        {options.length > searchBreakpoint && (
          <div className={styles.searchFieldContainer}>
            <SearchField onChange={debounced} aria-label={`${placeholder} MultiSelect Search`}>
              <Group>
                <Icon name="search" size="small" />
                <Input placeholder={placeholder} />
                <IconButton aria-label="clear" icon="cancel-circle-outline" size="small" variant="minimal" />
              </Group>
            </SearchField>
          </div>
        )}
        <Menu
          onSelectionChange={handleSelect}
          selectedKeys={selectedKeys}
          selectionMode="multiple"
          className={styles.menu}
          aria-label={ariaLabel}
          disallowEmptySelection={disallowEmptySelection}
        >
          {displayedOptions.map((o, index) => (
            <MenuItem
              id={o.value}
              key={`MenuItem-${o?.label}-${index}`}
              className={styles.menuItem}
              aria-label={o.label}
            >
              {o?.label}
            </MenuItem>
          ))}
        </Menu>
      </Popover>
    </MenuTrigger>
  );
}
