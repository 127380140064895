import { schemas } from '@gonfalon/openapi';
import { ExperimentAnalysis, TreatmentAnalysis } from '@gonfalon/rest-api';

export type BayesianBetaAnalysis = schemas['BayesianBetaAnalysisRep'];
export type BayesianNormalAnalysis = schemas['BayesianNormalAnalysisRep'];
export type BayesianRelativeDifferenceAnalysis = schemas['RelativeDifferenceAnalysisRep'];
export type CupedAnalysis = schemas['CUPEDAnalysisRep'];
export type Experiment = schemas['Experiment'];
export type AnalysisConfig = schemas['AnalysisConfigRep'];
export type { ExperimentAnalysis, TreatmentAnalysis } from '@gonfalon/rest-api';
export type FrequentistBetaAnalysis = schemas['FrequentistBetaAnalysisRep'];
export type FrequentistNormalAnalysis = schemas['FrequentistNormalAnalysisRep'];
export type FrequentistRelativeDifferenceAnalysis = schemas['FrequentistRelativeDifferenceAnalysisRep'];
export type Iteration = schemas['IterationRep'];
export type MetricAnalysis = schemas['MetricAnalysisRep'];
export type RandomizationUnit = schemas['RandomizationUnitRep'];
export type Treatment = schemas['TreatmentRep'];
export type ExperimentBayesianResults = schemas['ExperimentBayesianResultsRep'];
export type ExperimentQuantileResults = schemas['ExperimentQuantileResultsRep'];
export type TreatmentResult = schemas['TreatmentResultRep'];
export type TreatmentQuantileResult = schemas['TreatmentQuantileResultRep'];
export type DependentExperiment = schemas['DependentExperimentRep'];
export type ExperimentTimeSeriesResults = schemas['ExperimentTimeseriesResultsRep'];
export type Holdout = schemas['HoldoutDetailRep'];
export type HoldoutCollection = schemas['HoldoutsCollectionRep'];
export type HoldoutPostPayload = schemas['HoldoutPostRequest'];
export type Layer = schemas['LayerRep'];
export type LayerCollection = schemas['LayerCollectionRep'];
export type LayerReservation = schemas['LayerReservationRep'];
export type LayerSnapshot = schemas['LayerSnapshotRep'];
export type ExperimentResultsLastUpdatedCollection = schemas['ExperimentResultsLastUpdatedCollectionRep'];

export type AnalysisColumnRenderContext = {
  experiment: Experiment;
  iteration: Iteration;
  experimentAnalysis: ExperimentAnalysis;
};

export type AnalysisCellRenderContext = AnalysisColumnRenderContext & {
  treatment: Treatment;
  treatmentAnalysis: TreatmentAnalysis;
  slice: { attributeName: string; attributeValue: string | number | boolean } | undefined;
};

type RelativeDifferenceSchema = schemas['RelativeDifferenceRep'];
export interface RelativeDifference extends RelativeDifferenceSchema {
  fromTreatmentId: string;
  lower: number;
  upper: number;
}

type CredibleInterval = schemas['CredibleIntervalRep'];
export type NormalizedTreatmentResult = (TreatmentResult | TreatmentQuantileResult) & {
  valueInterval: Required<CredibleInterval>;
  relativeDifferences: RelativeDifference[];
};

export type CredibleIntervalTimeSeries = Array<{
  treatmentName?: string;
  treatmentId?: string;
  values?: CredibleIntervalTimeSeriesValue[];
}>;

export type CredibleIntervalTimeSeriesValue = {
  timestamp: number;
  pointEstimate: number;
  lowerBound: number;
  upperBound: number;
  traffic: number;
  units: number;
  mean: number;
  data: {
    relativeDifferences: RelativeDifference[];
  };
};

export type HoldoutStatus = Holdout['status'];

export type AIGeneratedItem = schemas['AIGeneratedItem'];

export type AIGeneratedExperimentConfig = schemas['AIGeneratedExperimentConfig'];

export type SuggestedItem = schemas['SuggestedItem'];

export enum ExperimentTypeKind {
  FEATURE = 'feature',
  FUNNEL = 'funnel',
  EXPORT_ONLY = 'export_only',
  SNOWFLAKE_NATIVE = 'snowflake_native',
}

export type ExperimentType = Lowercase<keyof typeof ExperimentTypeKind>;
