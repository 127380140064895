import { memo, useCallback } from 'react';
import ReactDragListView from 'react-drag-listview';
import { Flex, message, Select } from 'antd';

import fuzzySearch from '../../../../utils/fuzzySearch';

import EventDetailsQuickViewModal from './EventDetailsQuickViewModal';
import EventSelectorItem from './EventSelectorItem';
import getEventLabels from './getEventLabels';

const SortableList = memo(({ disableSorting, children, onSort }) => {
  if (disableSorting) {
    return children;
  }
  return (
    <ReactDragListView
      nodeSelector=".funnel-event-item-root"
      handleSelector=".funnel-event-drag-handle"
      lineClassName="block-rearrange-line"
      onDragEnd={onSort}
    >
      {children}
    </ReactDragListView>
  );
});

export default function EventsSelector({
  disabled = false,
  selectedEvents,
  updateSelectedEvents,
  eventsData,
  selectableEvents,
  disableSorting = false,
  showLabel = false,
  showSteps = false,
  enableConversionCriteria = false,
  maxStepsCount,
  allowLabelRename = false,
  enableFirstTimeFilter = true,
  enableFirstTimeFilterCustomEvent = false,
  renderExtraOptions = null,
  customEventData = [],
  shouldShowEventOperator = false,
  shouldTruncateEventName = true,
  shouldShowEntities = false,
}) {
  const updateEvent = (updatedEvent, eventIndex) => {
    const allDimensions =
      eventsData?.dimensions?.filter((dim) => dim.dimension_type !== 'unassigned')?.map((dim) => dim.name) || [];
    const filteredEventPropertiesSet = new Set();
    const filteredEventProperties = updatedEvent?.filters?.and
      ?.filter((filter) => {
        const isEventProperty = filter?.source === undefined || filter?.source === 'event';

        if (
          filter?.field &&
          !allDimensions.includes(filter.field) &&
          !filteredEventPropertiesSet.has(filter.field) &&
          isEventProperty
        ) {
          filteredEventPropertiesSet.add(filter.field);
          return true;
        }
        return false;
      })
      .map((filter) => filter.field);
    updateSelectedEvents((prev) => {
      if (maxStepsCount === 1) {
        return {
          name: updatedEvent.name,
          ...updatedEvent,
          filtered_event_properties: filteredEventProperties,
        };
      }
      const newSelectedEvents = [...(prev || [])];
      newSelectedEvents.splice(eventIndex, 1, {
        ...updatedEvent,
        filtered_event_properties: filteredEventProperties,
      });
      return newSelectedEvents;
    });
  };

  const updateEventOrder = useCallback(
    (fromIndex, toIndex) => {
      if (toIndex < 0) {
        return;
      } // Ignores if outside designated area
      const copyOfSelectedEvents = [...selectedEvents];
      const movedEvent = copyOfSelectedEvents.splice(fromIndex, 1)[0];
      copyOfSelectedEvents.splice(toIndex, 0, movedEvent);
      updateSelectedEvents(() => copyOfSelectedEvents);
    },
    [selectedEvents, updateSelectedEvents],
  );

  const deleteEvent = useCallback(
    (indexOfEvent) => {
      updateSelectedEvents((prev) => {
        if (maxStepsCount === 1) {
          return {};
        }
        let newEvents = prev.filter((e, index) => index !== indexOfEvent);
        if (newEvents[0]?.label) {
          newEvents = newEvents.map((e, index) => {
            const { hasCustomLabel, customLabel } = getEventLabels(e);
            // if an event has a custom label defined, keep it
            if (hasCustomLabel) {
              return {
                ...e,
                label: `${String.fromCharCode(65 + index)}: ${customLabel}: ${e.name}`,
              };
            }
            return {
              ...e,
              label: `${String.fromCharCode(65 + index)}: ${e.name}`,
            };
          });
        }

        return newEvents;
      });
    },
    [maxStepsCount, updateSelectedEvents],
  );

  if (maxStepsCount === 1) {
    return selectedEvents?.name ? (
      <div>
        <SortableList disableSorting={disableSorting || disabled} onSort={updateEventOrder}>
          <EventSelectorItem
            selectedEvents={selectedEvents}
            showLabel={showLabel}
            showSteps={showSteps}
            key={selectedEvents.label}
            event={selectedEvents}
            eventIndex={1}
            eventsData={eventsData}
            updateEvent={updateEvent}
            disableSorting={disableSorting || disabled}
            selectableEvents={selectableEvents}
            onDelete={deleteEvent}
            enableConversionCriteria={enableConversionCriteria}
            allowLabelRename={allowLabelRename}
            enableFirstTimeFilter={enableFirstTimeFilter}
            enableFirstTimeFilterCustomEvent={enableFirstTimeFilterCustomEvent}
            renderExtraOptions={renderExtraOptions}
            customEventData={customEventData}
            shouldTruncateEventName={shouldTruncateEventName}
            shouldShowEntities={shouldShowEntities}
          />
        </SortableList>
      </div>
    ) : (
      <Select
        disabled={disabled}
        placeholder="Search Event..."
        style={{ width: '100%' }}
        optionFilterProp="label"
        showSearch
        allowClear
        value={selectedEvents?.name}
        onChange={(value) => {
          updateSelectedEvents(() => {
            const selectedEventObject = selectableEvents?.find((e) => (e?.name ?? e) === value);

            return {
              name: value,
              label: `${String.fromCharCode(65)}: ${value}`,
              custom_event_uuid: selectedEventObject?.custom_event_uuid,
            };
          });
        }}
        filterOption={(inputValue, option) => {
          const selectedEventObject = selectableEvents.find((e) => (e?.name ?? e) === option.value);
          const searchPayload = [selectedEventObject?.name, selectedEventObject?.description ?? ''];
          if (selectedEventObject.custom_event_uuid) {
            searchPayload.push(
              customEventData.find((e) => e?.event_uuid === selectedEventObject.custom_event_uuid)?.description ?? '',
            );
          }
          return fuzzySearch(searchPayload, inputValue);
        }}
      >
        {selectableEvents?.map((eventItem) => {
          const eventName = typeof eventItem === 'string' ? eventItem : eventItem.name;
          return (
            <Select.Option key={eventName} label={eventName} value={eventName}>
              <EventDetailsQuickViewModal eventItem={eventItem} shouldTruncateEventName={shouldTruncateEventName} />
            </Select.Option>
          );
        })}
      </Select>
    );
  }

  const selectedMaximumNoOfEvents = maxStepsCount ? maxStepsCount <= selectedEvents.length : false;

  return (
    <Flex vertical gap={8}>
      {selectedEvents.length > 0 && (
        <div>
          <SortableList disableSorting={disableSorting || disabled} onSort={updateEventOrder}>
            <Flex vertical gap={8}>
              {selectedEvents.map((event, selectedEventIndex) => (
                <EventSelectorItem
                  selectedEvents={selectedEvents}
                  showEventOperator={
                    shouldShowEventOperator &&
                    (selectedEvents.length > 1 && selectedEventIndex < selectedEvents.length - 1 ? 'or' : undefined)
                  }
                  shouldShowEntities={shouldShowEntities}
                  showLabel={showLabel}
                  showSteps={showSteps}
                  key={event.label}
                  event={event}
                  eventIndex={selectedEventIndex}
                  eventsData={eventsData}
                  updateEvent={updateEvent}
                  disableSorting={disableSorting}
                  selectableEvents={selectableEvents}
                  onDelete={deleteEvent}
                  enableConversionCriteria={enableConversionCriteria}
                  allowLabelRename={allowLabelRename}
                  disabled={disabled}
                  enableFirstTimeFilter={enableFirstTimeFilter}
                  enableFirstTimeFilterCustomEvent={enableFirstTimeFilterCustomEvent}
                  renderExtraOptions={renderExtraOptions}
                  customEventData={customEventData}
                  shouldTruncateEventName={shouldTruncateEventName}
                />
              ))}
            </Flex>
          </SortableList>
        </div>
      )}
      {!disabled && !selectedMaximumNoOfEvents && (
        <Select
          placeholder="Search Event..."
          style={{ width: '100%' }}
          optionFilterProp="label"
          showSearch
          showArrow
          allowClear
          value={null}
          onChange={(value) => {
            if (selectedEvents.length === maxStepsCount) {
              message.warning(`You can only add upto ${maxStepsCount} events`);
              return;
            }
            updateSelectedEvents((prev) => {
              const selectedEventObject = selectableEvents.find((e) => (e?.name ?? e) === value);

              return [
                ...prev,
                {
                  name: value,
                  label: `${String.fromCharCode(65 + prev.length)}: ${value}`,
                  custom_event_uuid: selectedEventObject?.custom_event_uuid,
                },
              ];
            });
          }}
          filterOption={(inputValue, option) => {
            const selectedEventObject = selectableEvents.find((e) => (e?.name ?? e) === option.value);
            const searchPayload = [selectedEventObject?.name, selectedEventObject?.description ?? ''];
            if (selectedEventObject.custom_event_uuid) {
              searchPayload.push(
                customEventData.find((e) => e?.event_uuid === selectedEventObject.custom_event_uuid)?.description ?? '',
              );
            }
            return fuzzySearch(searchPayload, inputValue);
          }}
        >
          {selectableEvents?.map((eventItem) => {
            const eventName = typeof eventItem === 'string' ? eventItem : eventItem.name;
            return (
              <Select.Option key={eventName} label={eventName} value={eventName}>
                <EventDetailsQuickViewModal eventItem={eventItem} shouldTruncateEventName={shouldTruncateEventName} />
              </Select.Option>
            );
          })}
        </Select>
      )}
    </Flex>
  );
}
