import { AnalysisConfig } from '../types';

import {
  ArchiveExperimentInstruction,
  ExperimentPatchInstructionKind,
  RestoreExperimentInstruction,
  StartIterationInstruction,
  StopIterationInstruction,
  UpdateExperimentDataSourceInstruction,
  UpdateExperimentDescriptionInstruction,
  UpdateExperimentMethodologyInstruction,
  UpdateExperimentNameInstruction,
} from './types';

export function makeStartIterationInstruction(changeJustification: string): StartIterationInstruction {
  return {
    kind: ExperimentPatchInstructionKind.START_ITERATION,
    changeJustification,
  };
}

export function makeStopIterationInstruction(
  winningTreatmentId: string,
  winningReason: string,
): StopIterationInstruction {
  return {
    kind: ExperimentPatchInstructionKind.STOP_ITERATION,
    winningTreatmentId,
    winningReason,
  };
}

export function makeUpdateExperimentNameInstruction(value: string): UpdateExperimentNameInstruction {
  return {
    kind: ExperimentPatchInstructionKind.UPDATE_NAME,
    value,
  };
}

export function makeUpdateExperimentDescriptionInstruction(value: string): UpdateExperimentDescriptionInstruction {
  return {
    kind: ExperimentPatchInstructionKind.UPDATE_DESCRIPTION,
    value,
  };
}

export function makeArchiveExperimentInstruction(): ArchiveExperimentInstruction {
  return {
    kind: ExperimentPatchInstructionKind.ARCHIVE_EXPERIMENT,
  };
}

export function makeRestoreExperimentInstruction(): RestoreExperimentInstruction {
  return {
    kind: ExperimentPatchInstructionKind.RESTORE_EXPERIMENT,
  };
}

export function makeUpdateExperimentMethodologyInstruction(
  methodology: string,
  analysisConfig?: AnalysisConfig,
): UpdateExperimentMethodologyInstruction {
  return {
    kind: ExperimentPatchInstructionKind.UPDATE_METHODOLOGY,
    methodology,
    analysisConfig,
  };
}

export function makeUpdateDataSourceInstruction(
  dataSource: 'launchdarkly' | 'snowflake',
): UpdateExperimentDataSourceInstruction {
  return {
    kind: ExperimentPatchInstructionKind.UPDATE_DATASOURCE,
    dataSource,
  };
}
