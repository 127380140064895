// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Record } from 'immutable';

import { RequestState } from './reduxUtils';

export type TeamsBulkAddMembersResponseItem = {
  message?: string;
  status: string;
  value: string;
};

type TeamsBulkAddMembersResponseProps = {
  items: List<TeamsBulkAddMembersResponseItem>;
};

export class TeamsBulkAddMembersResponse extends Record<TeamsBulkAddMembersResponseProps>({
  items: List(),
}) {}

export function createTeamsBulkAddMembersResponse(props: Partial<TeamsBulkAddMembersResponseProps>) {
  return props instanceof TeamsBulkAddMembersResponse ? props : new TeamsBulkAddMembersResponse(fromJS(props));
}

// globalErrors indicate errors that occurred at the file level
// See: https://launchdarkly.com/docs/home/account/manage-teams#troubleshoot-global-errors
const globalErrors = [
  'All emails belong to existing team members',
  'All emails have invalid formatting',
  'File is empty',
  'Missing file',
  'No emails belong to members of your LaunchDarkly organization',
  'Unable to process file',
];

type TalliedBulkAddMembersResult = {
  success: string[];
  error: string[];
};

export function tallyBulkAddMembersResult(response: TeamsBulkAddMembersResponse): TalliedBulkAddMembersResult {
  return response.items.toJS().reduce(
    (talliedResult, { status, value }): TalliedBulkAddMembersResult => {
      if (status === 'success') {
        talliedResult.success.push(value);
      } else {
        talliedResult.error.push(value);
      }

      return talliedResult;
    },
    { success: [] as string[], error: [] as string[] } as TalliedBulkAddMembersResult,
  );
}

export enum TeamsBulkAddMembersResult {
  IS_SUCCESS = 'isSuccess',
  IS_PARTIAL_SUCCESS = 'isPartialSuccess',
  IS_FAILED = 'isFailed',
  IS_FAILED_WITH_MULTIPLE_REASONS = 'isFailedWithMultipleReasons',
  IS_MAX_FILE_SIZE_EXCEEDED = 'isMaxFileFileExceeded',
  IS_PENDING = 'isPending',
}

// uploadFileConditions are conditions when a selected file is automatically uploaded without pressing the modal's submit button.
// A file is considered "selected" when the user opens a file using their OS's native file explorer.
export const uploadFileConditions = [
  TeamsBulkAddMembersResult.IS_PARTIAL_SUCCESS,
  TeamsBulkAddMembersResult.IS_FAILED,
  TeamsBulkAddMembersResult.IS_FAILED_WITH_MULTIPLE_REASONS,
];

export const renderErrorListConditions = [
  TeamsBulkAddMembersResult.IS_PARTIAL_SUCCESS,
  TeamsBulkAddMembersResult.IS_FAILED_WITH_MULTIPLE_REASONS,
];

export const teamsCsvMaxFileSize = 25000000;

export function getTeamsBulkAddMembersResult(
  talliedResult: TalliedBulkAddMembersResult,
  request: RequestState,
  isInitialPrompt: boolean,
  selectedFile: File | null,
): TeamsBulkAddMembersResult {
  const successCount = talliedResult.success.length;
  const errorCount = talliedResult.error.length;
  const hasValidEmails = successCount > 0;
  const hasInvalidEmails = errorCount > 0;
  const is400 = request.get('error')?.get('status') === 400;
  const errResponseMessage = request.get('error')?.get('message') ?? '';

  if (hasValidEmails && !hasInvalidEmails && !is400) {
    return TeamsBulkAddMembersResult.IS_SUCCESS;
  } else if (hasInvalidEmails && hasValidEmails && !is400) {
    return TeamsBulkAddMembersResult.IS_PARTIAL_SUCCESS;
  } else if (globalErrors.includes(errResponseMessage) && is400) {
    return TeamsBulkAddMembersResult.IS_FAILED;
  } else if (!hasValidEmails && hasInvalidEmails && !is400) {
    return TeamsBulkAddMembersResult.IS_FAILED_WITH_MULTIPLE_REASONS;
  } else if (!!selectedFile && selectedFile.size > teamsCsvMaxFileSize) {
    return TeamsBulkAddMembersResult.IS_MAX_FILE_SIZE_EXCEEDED;
  } else {
    return TeamsBulkAddMembersResult.IS_PENDING;
  }
}

export function generateWarningMessage(talliedResult: TalliedBulkAddMembersResult): string {
  return `${talliedResult.success.length} lines processed successfully. Errors found in ${
    talliedResult.error.length
  } of ${talliedResult.success.length + talliedResult.error.length} lines:`;
}

export function generateFailedMessage(uploadResult: TeamsBulkAddMembersResult, errResponseMessage: string) {
  switch (uploadResult) {
    case TeamsBulkAddMembersResult.IS_MAX_FILE_SIZE_EXCEEDED:
      return `File exceeds ${Math.trunc(teamsCsvMaxFileSize / 1000000)} MB`;
    case TeamsBulkAddMembersResult.IS_FAILED:
      return errResponseMessage ?? 'Unable to process file';
    default:
      return 'Unable to process file';
  }
}
