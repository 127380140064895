import { MemberDataRep } from '@gonfalon/audit-log';
import { isThirdPartyScriptsEnabled } from '@gonfalon/dogfood-flags';
import { getGravatarUrl } from '@gonfalon/gravatar';
import { type AvatarProps, Avatar } from '@launchpad-ui/components';
import cx from 'clsx';
import isEmail from 'validator/lib/isEmail';

import { Member, MemberSummary } from 'utils/accountUtils';

type UserAvatarProps = {
  url?: string | null;
  email?: string | null;
  size?: AvatarProps['size'];
  className?: string;
  member?: Member | MemberSummary | MemberDataRep;
  avatarUrl?: string;
  'data-test-id'?: string;
};

/* eslint-disable import/no-default-export */
export default function UserAvatar({
  url: passedUrl,
  email: passedEmail,
  className,
  size,
  member,
  avatarUrl: userAvatarUrl,
  'data-test-id': testId = 'user-avatar',
}: UserAvatarProps) {
  const email = passedEmail ?? member?.email;
  // @ts-expect-error -- TSCONVERSION
  const avatarUrl = member?._avatarUrl || userAvatarUrl;

  const isValidEmail = email && isEmail(email);
  const useGravatar = isValidEmail && isThirdPartyScriptsEnabled();

  let url = '';
  if (__DEV__) {
    // when running locally, leave url as ''
    // so we don't try to fetch the Gravatar image
  } else if (avatarUrl) {
    url = avatarUrl;
  } else if (passedUrl) {
    url = passedUrl;
  } else if (useGravatar) {
    url = getGravatarUrl(email, {
      // instruct gravatar to 404 if it has no image for the given email
      // this is handled in Avatar with a fallback to our default
      default: '404',
      size: 48,
    });
  }

  const initials =
    member?.firstName?.length && member?.lastName?.length ? `${member.firstName[0]}${member.lastName[0]}` : 'N/A';

  return (
    <Avatar
      alt="Member avatar"
      aria-label="Member avatar"
      src={url}
      size={size}
      data-test-id={testId}
      className={cx(className, 'fs-exclude')}
    >
      {initials}
    </Avatar>
  );
}
