import GridSkeleton from 'components/skeletons/GridSkeleton';
import { Cell } from 'components/ui/grid';

import styles from './ListEmptyState.module.css';

type ListEmptyStateHeadingProps = {
  children: React.ReactNode;
};

type ListEmptyStateLoadingProps = {
  children: React.ReactNode[];
};

export function ListEmptyStateLoading({ children }: ListEmptyStateLoadingProps) {
  return (
    <GridSkeleton className="u-pa-l" direction="row">
      {children}
    </GridSkeleton>
  );
}
export const renderSkeletonLoading = (numberOfSkeletonRows: number) =>
  Array.from({ length: numberOfSkeletonRows }, (_, i) => (
    <Cell key={i}>
      <span className="block SkeletonApp--loading SkeletonApp-table-row--loading-thin" />
    </Cell>
  ));

export function ListEmptyStateHeading({ children }: ListEmptyStateHeadingProps) {
  return <h2 className={styles.heading}>{children}</h2>;
}

type ListEmptyStateDescriptionProps = {
  children: React.ReactNode;
};
export function ListEmptyStateDescription({ children }: ListEmptyStateDescriptionProps) {
  return <div className={styles.description}>{children}</div>;
}

type ListEmptyStateActionsProps = {
  children: React.ReactNode;
};
export function ListEmptyStateActions({ children }: ListEmptyStateActionsProps) {
  return <div className={styles.actions}>{children}</div>;
}

type ListEmptyStateProps = {
  children: React.ReactNode;
};
export function ListEmptyState({ children }: ListEmptyStateProps) {
  return <div className={styles.container}>{children}</div>;
}
