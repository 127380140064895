import { type AvatarProps, Avatar } from '@launchpad-ui/components';

type IntegrationAvatarProps = {
  url?: string | null;
  size?: AvatarProps['size'];
  className?: string;
};

export function IntegrationAvatar({ url: passedUrl, className, size }: IntegrationAvatarProps) {
  const url = passedUrl || '';

  return <Avatar alt="A logo for this integration" src={url} size={size || undefined} className={className} />;
}
