import { AnalysisConfig } from '../types';

export enum ExperimentPatchInstructionKind {
  START_ITERATION = 'startIteration',
  STOP_ITERATION = 'stopIteration',
  UPDATE_NAME = 'updateName',
  UPDATE_DESCRIPTION = 'updateDescription',
  ARCHIVE_EXPERIMENT = 'archiveExperiment',
  RESTORE_EXPERIMENT = 'restoreExperiment',
  UPDATE_METHODOLOGY = 'updateMethodology',
  UPDATE_DATASOURCE = 'updateDataSource',
}

export type StartIterationInstruction = {
  kind: ExperimentPatchInstructionKind.START_ITERATION;
  changeJustification: string;
};

export type StopIterationInstruction = {
  kind: ExperimentPatchInstructionKind.STOP_ITERATION;
  winningTreatmentId: string;
  winningReason: string;
};

export type UpdateExperimentNameInstruction = {
  kind: ExperimentPatchInstructionKind.UPDATE_NAME;
  value: string;
};

export type UpdateExperimentDescriptionInstruction = {
  kind: ExperimentPatchInstructionKind.UPDATE_DESCRIPTION;
  value: string;
};

export type ArchiveExperimentInstruction = {
  kind: ExperimentPatchInstructionKind.ARCHIVE_EXPERIMENT;
};

export type RestoreExperimentInstruction = {
  kind: ExperimentPatchInstructionKind.RESTORE_EXPERIMENT;
};

export type UpdateExperimentMethodologyInstruction = {
  kind: ExperimentPatchInstructionKind.UPDATE_METHODOLOGY;
  methodology: string;
  analysisConfig?: AnalysisConfig;
};

export type UpdateExperimentDataSourceInstruction = {
  kind: ExperimentPatchInstructionKind.UPDATE_DATASOURCE;
  dataSource: 'launchdarkly' | 'snowflake';
};

export type ExperimentPatchInstruction =
  | StartIterationInstruction
  | StopIterationInstruction
  | UpdateExperimentNameInstruction
  | UpdateExperimentDescriptionInstruction
  | ArchiveExperimentInstruction
  | RestoreExperimentInstruction
  | UpdateExperimentMethodologyInstruction
  | UpdateExperimentDataSourceInstruction;

// Example usage:
//
// function applyInstruction(experiment: Experiment, instruction: ExperimentInstruction): Experiment {
//   switch (instruction.kind) {
//     case ExperimentInstructionKind.START_ITERATION:
//       // ...
//       return experiment;
//     // handle the rest...
//     default:
//       return experiment;
//   }
// }
