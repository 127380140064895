// eslint-disable-next-line no-restricted-imports
import { useSelector } from 'react-redux';
// eslint-disable-next-line no-restricted-imports
import { matchPath } from 'react-router';
import { profile as _profile } from '@gonfalon/bootstrap-data';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux';
import { createSelector, OutputSelector } from 'reselect';

import { BillingAction } from 'actions/billing';
import { FormAction } from 'actions/forms';
import { MemberAction } from 'actions/members';
import { ProfileAction } from 'actions/profile';
import { TeamsAction } from 'actions/teams';
import { GlobalState } from 'reducers';
import { createRequestReducer } from 'reducers/createRequestReducer';
import paginate from 'reducers/paginate';
import registry from 'reducers/registry';
import { searchSelector } from 'reducers/router';
import {
  BulkMemberInvite,
  createBulkMemberInvite,
  createMember,
  createNewJoinTeamRequest,
  createSuggestInviteMembers,
  Member,
  NewJoinTeamRequest,
} from 'utils/accountUtils';
import { createFormState, FormState } from 'utils/formUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { createMemberFilters, createMemberFiltersFromQueryMap, MemberFilters } from 'utils/memberUtils';
import { Team } from 'utils/teamsUtils';

import 'epics/members';

function getInitialBulkInviteState(initalState = {}) {
  const state = createFormState(createBulkMemberInvite(initalState));
  const match = matchPath('/settings/members/invite/:inviteEmail', window.location.pathname);
  if (match?.params.inviteEmail) {
    // allows for multiple emails in the URL separated by a comma such as:
    // /settings/members/invite/email1@launchdarkly.com%2Cemail2@launchdarkly.com
    const initialData = createBulkMemberInvite({ emails: decodeURIComponent(match.params.inviteEmail).split(',') });
    return state.trackField('emails').revalidate(initialData);
  }

  return state;
}

export function bulkInviteForm(state = getInitialBulkInviteState(), action: MemberAction | FormAction | BillingAction) {
  switch (action.type) {
    case 'forms/INITIALIZE':
      if (action.model !== 'bulkInviteForm') {
        return state;
      }
      return getInitialBulkInviteState(action.initialState as FormState<BulkMemberInvite>);
    case 'members/EDIT_BULK_INVITE':
      const { field, value, seatsLeft } = action;
      const modified = state.modified.set(field, value);
      return state.trackField(field).revalidate(modified, seatsLeft);
    case 'members/INVITE_MEMBERS':
    case 'billing/SUBSCRIBE':
      return state.submitting();
    case 'members/INVITE_MEMBERS_DONE':
    case 'billing/SUBSCRIBE_AND_INVITE_DONE':
      const failedEmails = action.invites.failedEmails;
      const subscriptionSuccessful = action.options?.inviteAndSubscribe;
      const rateLimitedEmails = failedEmails.get('rate_limited', List());
      const rest = failedEmails.delete('rate_limited').valueSeq().flatten(true).toList();
      if (rest.size) {
        return state.submitFailed(
          action.invites,
          fromJS({
            rateLimitedEmails,
            failedEmails: rest,
            successfulCount: action.invites.successfulEmails.size,
            subscriptionSuccessful,
          }),
        );
      } else {
        return getInitialBulkInviteState();
      }
    case 'members/BULK_INVITE_MEMBERS_FAILED':
      let errorResponse = action.error;
      if (!action?.error?.get('status')) {
        // If an unknown error occurs, set the status so we can show the error message and close the modal.
        errorResponse = action.error.set('status', 500);
      }
      return state.submitFailed(action.invites, errorResponse);
    case 'forms/BLUR':
      if (action.model !== 'bulkInviteForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case 'forms/DESTROY':
      if (action.model !== 'bulkInviteForm') {
        return state;
      }
      return getInitialBulkInviteState();
    default:
      return state;
  }
}

export const bulkInviteProgress = (
  state = fromJS({
    total: undefined,
    completed: 0,
  }),
  action: MemberAction | BillingAction,
) => {
  switch (action.type) {
    case 'members/INVITE_MEMBERS':
      return state.set('completed', 0).set('total', action.invites.size());
    case 'members/INVITE_MEMBERS_BATCH_DONE':
      return state.update('completed', (p: number) => p + action.members.size);
    case 'members/INVITE_MEMBERS_DONE':
    case 'billing/SUBSCRIBE_AND_INVITE_DONE':
      return state.set('completed', state.get('total'));
    default:
      return state;
  }
};

const initialSuggestInviteMembersState = createFormState(createSuggestInviteMembers({ emails: [] }));
export function suggestInviteMembersForm(state = initialSuggestInviteMembersState, action: MemberAction | FormAction) {
  switch (action.type) {
    case 'members/EDIT_SUGGEST_INVITE_MEMBERS':
      const { field, value } = action;
      const modified = state.modified.set(field, value);
      return state.trackField(field).revalidate(modified);
    case 'forms/BLUR':
      if (action.model !== 'suggestInviteMembersForm') {
        return state;
      }
      return state.handleBlur(action.field, state.modified);
    case 'forms/DESTROY':
      if (action.model !== 'suggestInviteMembersForm') {
        return state;
      }
      return initialSuggestInviteMembersState;
    case 'members/SEND_SUGGEST_INVITE_MEMBERS':
      return state.submitting();
    case 'members/SEND_SUGGEST_INVITE_MEMBERS_DONE':
      return initialSuggestInviteMembersState;
    case 'members/SEND_SUGGEST_INVITE_MEMBERS_FAILED':
      let errorResponse = action.error;
      if (!action?.error?.get('status')) {
        errorResponse = action.error.set('status', 500);
      }
      return state.submitFailed(action.suggestedInvites, errorResponse);
    default:
      return state;
  }
}

const membersPagination = paginate({
  types: ['members/REQUEST_MEMBERS', 'members/REQUEST_MEMBERS_FAILED', 'members/RECEIVE_MEMBERS'],
  invalidateTypes: [
    'members/INVITE_MEMBERS_DONE',
    'members/DELETE_MEMBER_DONE',
    'billing/SUBSCRIBE_AND_INVITE_DONE',
    'teams/CREATE_TEAM_DONE',
    'teams/UPDATE_TEAM_DONE',
    'members/BULK_REPLACE_MEMBERS_ROLES_DONE',
    'members/BULK_ADD_MEMBERS_TO_TEAMS_DONE',
  ],
  mapActionToKey: (action) => membersListKey(action?.filter),
});

const membersByTeamPagination = paginate({
  types: [
    'members/REQUEST_MEMBERS_BY_TEAM',
    'members/REQUEST_MEMBERS_BY_TEAM_FAILED',
    'members/RECEIVE_MEMBERS_BY_TEAM',
  ],
  invalidateTypes: ['members/DELETE_MEMBER_DONE'],
  mapActionToKey: () => 'all',
  // the result of this function will be the key, the paginate result the value.
});

function getMemberEntitiesInitialState() {
  const state: Map<string, Member> = Map();
  const profile = _profile();

  if (profile) {
    return state.set(profile._id, createMember(fromJS(profile)));
  }

  return state;
}

function membersEntities(
  state = getMemberEntitiesInitialState(),
  action: MemberAction | ProfileAction | TeamsAction,
): Map<string, Member> {
  switch (action.type) {
    case 'members/RECEIVE_MEMBERS':
      const entities = action.response
        .getIn(['entities', 'members'])
        .reduce((map: Map<string, Member>, member: Member) => map.set(member._id, member), Map());
      return state.merge(entities);
    case 'members/INVITE_MEMBERS_BATCH_DONE':
      return state.withMutations((es) => {
        action.members.forEach((m: Member) => {
          es.set(m._id, m);
        });
      });
    case 'members/UPDATE_MEMBER_DONE':
      return state.set(action.member._id, action.member);
    case 'profile/REQUEST_PROFILE_DONE':
    case 'profile/UPDATE_PROFILE_DONE':
      return state.set(action.profile._id, action.profile);
    case 'members/DELETE_MEMBER_DONE':
      return state.delete(action.member._id);
    // TODO: remove code if no action remains inthe reducer after Bulk Edit Member is released
    // case 'members/BULK_REPLACE_MEMBERS_ROLES_DONE':
    // return state.withMutations((es) => {
    //   action.memberIDs?.forEach((memberID: string) => {
    //     es.setIn([memberID, 'role'], action.role);
    //   });
    // });
    case 'teams/REMOVE_MEMBER_FROM_TEAM_DONE':
      const memberTeams = state.getIn([action.memberId, 'teams']);
      if (memberTeams) {
        const teamsNotRemoved = memberTeams.filter((team: Team) => team.key !== action.team.key);
        return state.setIn([action.memberId, 'teams'], teamsNotRemoved);
      }
      return state;
    case 'members/BULK_ADD_MEMBERS_TO_TEAMS':
    default:
      return state;
  }
}

function memberEntity(state = createMember(), action: MemberAction | ProfileAction | TeamsAction) {
  switch (action.type) {
    case 'members/RECEIVE_MEMBER':
    case 'members/UPDATE_MEMBER_DONE':
    case 'members/ADD_MEMBER_TO_TEAMS_DONE':
      return action.member;
    case 'profile/UPDATE_PROFILE_DONE':
      return action.profile;
    case 'teams/REMOVE_MEMBER_FROM_TEAM_DONE':
      if (action.memberId === state._id) {
        const memberTeams = state.get('teams');
        const remainingTeams = memberTeams?.filter((team) => team.key !== action.team.key);
        return state.set('teams', remainingTeams);
      }
      return state;
    default:
      return state;
  }
}

export function membersListKey(filter?: MemberFilters) {
  return filter ? filter.toFrontendMembersQueryString() : 'all';
}

export function membersByTeamEntity(state = Map(), action: MemberAction) {
  // we need the team specific members to exist in redux separate from the general members entity
  if (action.type === 'members/RECEIVE_MEMBERS_BY_TEAM') {
    if (action.response && action.response.hasIn(['entities', 'members'])) {
      return action.response.getIn(['entities', 'members']);
    }
  }
  return state;
}

const memberEntityRequest = createRequestReducer([
  'members/REQUEST_MEMBER',
  'members/RECEIVE_MEMBER',
  'members/REQUEST_MEMBER_FAILED',
]);

export function totalMemberCountWithoutSelfOrOwner(state = null, action: MemberAction) {
  if (action.type === 'members/RECEIVE_MEMBERS') {
    const { response } = action;
    return response.getIn(['result', 'totalCountWithoutSelfOrOwner']) || null;
  }
  return state;
}

export function totalMemberCount(state = null, action: MemberAction) {
  if (action.type === 'members/RECEIVE_MEMBERS') {
    const { response } = action;
    return response.getIn(['result', 'totalCount']) || null;
  }
  return state;
}

export function filterMembersByTeamEntity(state = createMemberFilters({}), action: MemberAction) {
  // we need the filter so that pagination can work without updating the url
  if (action.type === 'members/RECEIVE_MEMBERS_BY_TEAM') {
    const filter = action.filter;
    return state.merge(filter);
  }
  return state;
}

export const members = combineReducers({
  entities: membersEntities,
  pagination: membersPagination,
  membersByTeamEntity,
  membersByTeamPagination,
  filterMembersByTeamEntity,
  entity: memberEntity,
  memberEntityRequest,
  totalMemberCountWithoutSelfOrOwner,
  totalMemberCount,
});

export const membersSelector = (state: GlobalState) => state.members;

export const membersEntitiesSelector = (state: GlobalState) => membersSelector(state).entities;
export const memberEntitySelector = (state: GlobalState) => membersSelector(state).entity;
export const memberEntityRequestSelector = (state: GlobalState) => membersSelector(state).memberEntityRequest;

export const membersPaginationByKeySelector = (key: string) => (state: GlobalState) =>
  membersSelector(state).pagination.get(key);

export const membersByTeamSelector = (state: GlobalState) => state.members.membersByTeamEntity.toList();
export const offsetByTeamSelector = (state: GlobalState) => state.members.filterMembersByTeamEntity.offset;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const membersPaginationByTeamSelector = (state: GlobalState) =>
  state.members.membersByTeamPagination.get('all')!; /* eslint-enable @typescript-eslint/no-non-null-assertion */

function memberEntitiesByKeySelector(key: string): OutputSelector<
  GlobalState,
  ImmutableMap<{
    entities: Map<string, Member>;
    isFetching: boolean;
    lastFetched: number | undefined;
  }>,
  (
    entities: ReturnType<typeof membersEntitiesSelector>,
    pagination: ReturnType<ReturnType<typeof membersPaginationByKeySelector>>,
  ) => ImmutableMap<{
    entities: Map<string, Member>;
    isFetching: boolean;
    lastFetched: number | undefined;
  }>
>;
function memberEntitiesByKeySelector(key: string) {
  return createSelector(membersEntitiesSelector, membersPaginationByKeySelector(key), (entities, pagination) =>
    fromJS(
      pagination
        ? {
            entities: pagination.get('ids').reduce((map, memberId) => map.set(memberId, entities.get(memberId)), Map()),
            isFetching: pagination.get('isFetching'),
            lastFetched: pagination.get('lastFetched'),
          }
        : {},
    ),
  );
}

export const allMembersSelector = memberEntitiesByKeySelector('all');
export const membersByKeySelector = (key: string) => memberEntitiesByKeySelector(key);

export const membersOffsetSelector = createSelector(searchSelector, (query) => (query ? query.offset : ''));
export const membersFilterSelector = createSelector(searchSelector, (query) => createMemberFiltersFromQueryMap(query));

const membersKeySelector = createSelector(searchSelector, (query) =>
  membersListKey(createMemberFiltersFromQueryMap(query)),
);

export const membersPaginationSelector = createSelector(membersKeySelector, membersSelector, (key, membersState) =>
  membersState.pagination.get(key),
);

export const memberListSelector = createSelector(
  membersEntitiesSelector,
  membersPaginationSelector,
  (entities, pagination) =>
    pagination
      ? pagination
          .get('ids')
          .toList()
          .map((id) => entities.get(id))
      : fromJS([]),
);

const membersCSVKeySelector = createSelector(searchSelector, (query) =>
  membersListKey(createMemberFiltersFromQueryMap(query).withoutPaginationQueryParams()),
);

export const membersCSVSelector = createSelector(membersCSVKeySelector, membersSelector, (key, membersState) =>
  membersState.pagination.get(key),
);

export const membersCSVListSelector = createSelector(
  membersEntitiesSelector,
  membersCSVSelector,
  (entities, pagination) =>
    pagination
      ? pagination
          .get('ids')
          .toList()
          .map((id) => entities.get(id))
      : fromJS([]),
);

export const memberSelector = (state: GlobalState, id: string) =>
  createSelector(membersEntitiesSelector, membersPaginationSelector, (entities, pagination) =>
    pagination
      ? fromJS({
          entity: entities.get(id),
          isFetching: pagination.get('isFetching'),
          lastFetched: pagination.get('lastFetched'),
        })
      : fromJS({}),
  )(state);

const initialInviteRequestState = fromJS({
  form: createFormState(createNewJoinTeamRequest()),
});

const intitialBulkInvitedMembersState = fromJS({
  members: [],
  failedMembers: [],
});

export const bulkInvitedMembers = (state = intitialBulkInvitedMembersState, action: MemberAction) => {
  switch (action.type) {
    case 'members/INVITE_MEMBERS_BATCH_DONE':
      return state.merge({
        members: action.members,
        failedMembers: action.failedMembers,
      });
    default:
      return state;
  }
};

export function requestTeamInviteForm(state = initialInviteRequestState, action: FormAction | MemberAction) {
  switch (action.type) {
    case 'members/EDIT_NEW_TEAM_INVITE_REQUEST':
      return state.update('form', (f: FormState<NewJoinTeamRequest>) =>
        f.trackField(action.field).revalidate(action.account),
      );
    case 'members/REQUEST_TEAM_INVITE':
      return state.update('form', (f: FormState<NewJoinTeamRequest>) => f.submitting());
    case 'members/REQUEST_TEAM_INVITE_DONE':
      return state.update('form', (f: FormState<NewJoinTeamRequest>) => f.submitted());
    case 'members/REQUEST_TEAM_INVITE_FAILED':
      return state.update('form', (f: FormState<NewJoinTeamRequest>) =>
        f.submitFailed(action.teamInviteRequest, action.error),
      );
    case 'forms/BLUR':
      if (action.model !== 'requestTeamInviteForm') {
        return state;
      }
      return state.update('form', (f: FormState<NewJoinTeamRequest>) => f.handleBlur(action.field, f.modified));
    default:
      return state;
  }
}
export const bulkInviteFormSelector = (state: GlobalState) => state.bulkInviteForm;
export const requestTeamInviteFormSelector = (state: GlobalState) => state.requestTeamInviteForm.get('form');

export const bulkInvitedMembersSelector = (state: GlobalState) => state.bulkInvitedMembers;

export const suggestInviteMembersFormSelector = (state: GlobalState) => state.suggestInviteMembersForm;

export const bulkInviteProgressSelector = createSelector(
  (state: GlobalState) => state.bulkInviteProgress,
  (bip) => {
    const total = bip.get('total');
    const completed = bip.get('completed');

    return {
      total,
      completed,
      percentage: total === undefined || total === null || total === 0 ? 0 : completed / total,
    };
  },
);

export const totalMemberCountWithoutSelfOrOwnerSelector = (state: GlobalState) =>
  state.members.totalMemberCountWithoutSelfOrOwner;

export const totalMemberCountSelector = (state: GlobalState) => state.members.totalMemberCount;

export const approveUnverifiedMemberRequest = createRequestReducer([
  'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_START',
  'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_DONE',
  'members/APPROVE_UNVERIFIED_MEMBER_REQUEST_FAILED',
]);

const approveUnverifiedMemberRequestSelector = (state: GlobalState) => state.approveUnverifiedMemberRequest;

export const useApproveUnverifiedMemberRequest = () => useSelector(approveUnverifiedMemberRequestSelector);

registry.addReducers({
  bulkInviteForm,
  bulkInviteProgress,
  members,
  requestTeamInviteForm,
  bulkInvitedMembers,
  approveUnverifiedMemberRequest,
  suggestInviteMembersForm,
});
