import { MeasuredRollout } from '.';

const EXTENDED_EVENT_KIND = 'minimum_monitoring_window_expired';

export function isMeasuredRolloutExtended(measuredRollout: MeasuredRollout): boolean {
  const finalStageIndex = measuredRollout.design.stages.length - 1;

  if (measuredRollout.state.latestStageIndex !== finalStageIndex) {
    return false;
  }

  if (!measuredRollout.state.events) {
    return false;
  }

  const finalStageHasExtendedEvent = measuredRollout.state.events.some(
    (event) => event.kind === EXTENDED_EVENT_KIND && event.stageIndex === finalStageIndex,
  );

  return finalStageHasExtendedEvent;
}
